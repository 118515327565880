import React, { useEffect } from "react"
import { useState } from "react"
import SEO from "../../components/seo"
import "./render-widget.css"
import diagramBG from "./render-widget-diagram-3.png"
import useLongPress from "../../hooks/use-long-press"
import confetti from "canvas-confetti"
import { graphql, useStaticQuery } from "gatsby"

const emojis = [
  <span role="img" aria-label="lion">
    🦁
  </span>,
  <span role="img" aria-label="cat">
    🐱
  </span>,
  <span role="img" aria-label="mouse">
    🐭
  </span>,
  <span role="img" aria-label="rabbit">
    🐰
  </span>,
  <span role="img" aria-label="fox">
    🦊
  </span>,
  <span role="img" aria-label="bear">
    🐻
  </span>,
  <span role="img" aria-label="panda">
    🐼
  </span>,
  <span role="img" aria-label="coala">
    🐨
  </span>,
  <span role="img" aria-label="tiger">
    🐯
  </span>,
  <span role="img" aria-label="frog">
    🐸
  </span>,
  <span role="img" aria-label="pig">
    🐷
  </span>,
]

const gameSteps = [
  {
    instructions: (
      <>
        <h3 className="rw__tutorial">Tutorial #1</h3>
        <p>
          Click on the root component {emojis[0]} to trigger a state update and{" "}
          <span className="rw__render">re-render</span> all components in the
          tree
        </p>
      </>
    ),
    success: {
      clickIndex: 0,
      memoIndexes: [],
    },
  },
  {
    instructions: (
      <>
        <h3 className="rw__tutorial">Tutorial #2</h3>
        <p>
          Click on component {emojis[1]} to trigger a state update and{` `}
          <span className="rw__render">re-render</span> all its children
        </p>
      </>
    ),
    success: {
      clickIndex: 1,
      memoIndexes: [],
    },
  },
  {
    instructions: (
      <>
        <h3 className="rw__tutorial">Tutorial #3</h3>
        <p>
          Long press on {emojis[7]} to wrap it with{" "}
          <span className="rw__memonote">memo</span>.<br />
          Then trigger update for {emojis[1]}
        </p>
      </>
    ),
    success: {
      clickIndex: 1,
      memoIndexes: [7],
    },
  },
  {
    instructions: (
      <>
        <h3 className="rw__tutorial">Tutorial #4</h3>
        <p>
          Long press {emojis[7]} again to remove{" "}
          <span className="rw__memonote">memo</span>.<br />
          Finally trigger a state update for {emojis[5]}
        </p>
      </>
    ),
    success: {
      clickIndex: 5,
      memoIndexes: [],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #1</h3>
        <p>Re-render {emojis[10]} with a single state update</p>
      </>
    ),
    success: {
      clickIndex: 10,
      memoIndexes: [],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #2</h3>
        <p>
          Re-render {emojis[6]} and {emojis[10]} with a single state update
        </p>
      </>
    ),
    success: {
      clickIndex: 6,
      memoIndexes: [],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #3</h3>
        <p>
          Re-render {emojis[2]}, {emojis[5]} {emojis[6]}, {emojis[9]} and{" "}
          {emojis[10]}
          <br />
          with a single state update
        </p>
      </>
    ),
    success: {
      clickIndex: 2,
      memoIndexes: [],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #4</h3>
        <p>
          <span className="rw__render">Re-render</span> all components except{" "}
          {emojis[8]}
          <br />
          You can only use <span className="rw__memonote">1 memo</span>
        </p>
      </>
    ),
    success: {
      clickIndex: 0,
      memoIndexes: [8],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #5</h3>
        <p>
          <span className="rw__render">Re-render</span> all comonents except{" "}
          {emojis[4]} and {emojis[8]}
          <br />
          Use only <span className="rw__memonote">1 memo</span>{" "}
        </p>
      </>
    ),
    success: {
      clickIndex: 0,
      memoIndexes: [4],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #6</h3>
        <p>
          <span className="rw__render">Re-render</span> all comonents except{" "}
          {emojis[1]}, {emojis[4]} and {emojis[8]}
          <br />
          Use only <span className="rw__memonote">1 memo</span>{" "}
        </p>
      </>
    ),
    success: {
      clickIndex: 0,
      memoIndexes: [1],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #7</h3>
        <p>
          <span className="rw__render">Re-render</span> only {emojis[0]}
          <br />
          Use <span className="rw__memonote">2 memo</span>{" "}
        </p>
      </>
    ),
    success: {
      clickIndex: 0,
      memoIndexes: [1, 2],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #8</h3>
        <p>
          <span className="rw__render">Re-render</span> only {emojis[1]}
          <br />
          Use <span className="rw__memonote">2 memo</span>{" "}
        </p>
      </>
    ),
    success: {
      clickIndex: 1,
      memoIndexes: [3, 4],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #9</h3>
        <p>
          <span className="rw__render">Re-render</span> only {emojis[2]}
          <br />
          Use <span className="rw__memonote">2 memo</span>{" "}
        </p>
      </>
    ),
    success: {
      clickIndex: 2,
      memoIndexes: [5, 6],
    },
  },
  {
    instructions: (
      <>
        <h3>Round #10</h3>
        <p>
          <span className="rw__render">Re-render</span> all components in the
          tree
        </p>
      </>
    ),
    success: {
      clickIndex: 0,
      memoIndexes: [],
    },
  },
  {
    instructions: (
      <>
        <h3>
          Congrats, you did it{" "}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </h3>
        <p>
          Learn more about React rendering in
          <br />
          <a target="_blank" href="/blog/react-render-always-rerenders/">
            A Visual Guide to React Rendering
          </a>
        </p>
      </>
    ),
    success: {
      clickIndex: 0,
      memoIndexes: [25],
    },
  },
]

function Component({ index, onClick, onLongPress, renderKey, memo, siteUrl }) {
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 400,
  }
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions)

  return (
    <button
      key={renderKey}
      {...longPressEvent}
      className={`rw__component ${memo ? "rw__memo" : ""}`}
    >
      {emojis[index]}
    </button>
  )
}

/**
 * Determine the amount of items
 * in every row of a pyramid and
 * the first index in every row
 */
const pyramid = [
  { itemsLength: 1, firstIndex: 0 },
  { itemsLength: 2, firstIndex: 1 },
  { itemsLength: 4, firstIndex: 3 },
  { itemsLength: 4, firstIndex: 7 },
]

function RenderGame() {
  const data = useStaticQuery(graphql`
    query RenderGameQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  const [currentStep, setCurrentStep] = useState(0)
  const siteUrl = data.site.siteMetadata.siteUrl

  const [components, setComponents] = useState([
    {
      children: [1, 2],
      parent: null,
      renderKey: 1,
      memo: false,
    },
    {
      children: [3, 4],
      parent: 0,
      renderKey: 1,
      memo: false,
    },
    {
      children: [5, 6],
      parent: 0,
      renderKey: 1,
      memo: false,
    },
    {
      children: [7],
      parent: 1,
      renderKey: 1,
      memo: false,
    },
    {
      children: [8],
      parent: 1,
      renderKey: 1,
      memo: false,
    },
    {
      children: [9],
      parent: 2,
      renderKey: 1,
      memo: false,
    },
    {
      children: [10],
      parent: 2,
      renderKey: 1,
      memo: false,
    },
    {
      children: [],
      parent: 3,
      renderKey: 1,
      memo: false,
    },
    {
      children: [],
      parent: 4,
      renderKey: 1,
      memo: false,
    },
    {
      children: [],
      parent: 5,
      renderKey: 1,
      memo: false,
    },
    {
      children: [],
      parent: 6,
      renderKey: 1,
      memo: false,
    },
  ])

  useEffect(() => {
    if (currentStep && currentStep !== 14) {
      confetti({
        particleCount: 50,
        spread: 70,
        origin: { y: 0.8 },
        ticks: 100,
      })
    }
    if (currentStep === 14) {
      var duration = 15 * 1000
      var animationEnd = Date.now() + duration
      var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min
      }

      var interval = setInterval(function () {
        var timeLeft = animationEnd - Date.now()

        if (timeLeft <= 0) {
          return clearInterval(interval)
        }

        var particleCount = 50 * (timeLeft / duration)
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          })
        )
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          })
        )
      }, 250)
    }
  }, [currentStep])

  return (
    <div className="rw">
      <SEO title="Render widget" image={`${siteUrl}/og/render-game.png`} />
      <h2>React Rendering Game</h2>
      <p className="rw__hint">Play to see how React re-renders components</p>
      <div className="rw__instruction">
        {gameSteps[currentStep].instructions}
      </div>
      <div
        className="rw__grid"
        style={{ backgroundImage: `url("${diagramBG}")` }}
      >
        {[...Array(4).keys()].map(i => {
          const itemsLength = pyramid[i].itemsLength
          const firstIndex = pyramid[i].firstIndex
          return (
            <div className="rw__row">
              {[...Array(itemsLength).keys()].map(j => {
                const index = firstIndex + j
                return (
                  <Component
                    index={index}
                    memo={components[index].memo}
                    onClick={() => {
                      const rerender = (components, index, first) => {
                        if (first || !components[index].memo) {
                          components[index].renderKey++
                          components[index].children.forEach(childIndex => {
                            rerender(components, childIndex)
                          })
                        }
                      }

                      const componentsCopy = [...components]
                      rerender(componentsCopy, index, true)
                      setComponents(componentsCopy)

                      const checkGameCondition = (
                        currentStep,
                        components,
                        index
                      ) => {
                        if (index !== gameSteps[currentStep].success.clickIndex)
                          return false

                        const memoIndexesCondition = gameSteps[
                          currentStep
                        ].success.memoIndexes.join("-")
                        const currentMemoIndexes = components
                          .map((component, index) =>
                            component.memo ? index : null
                          )
                          .filter(index => index !== null)
                          .join("-")

                        if (memoIndexesCondition !== currentMemoIndexes)
                          return false

                        return true
                      }

                      if (
                        checkGameCondition(currentStep, componentsCopy, index)
                      ) {
                        setCurrentStep(currentStep + 1)
                      }
                    }}
                    onLongPress={() => {
                      const componentsCopy = [...components]
                      componentsCopy[index].memo = !componentsCopy[index].memo
                      setComponents(componentsCopy)
                    }}
                    renderKey={components[index].renderKey}
                    key={index}
                  />
                )
              })}
            </div>
          )
        })}
      </div>
      <p className="rw__by">
        Built by <a href="/">Alex Sidorenko</a>
      </p>
    </div>
  )
}

export default RenderGame
